import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TitleSmall500, BodyMedium400 } from '@nebula/global-styles';
import Spinner from '@nebula/spinner';

const Container = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0 1rem;
  text-align: center;
  ${TitleSmall500} {
    margin-bottom: 0.25rem;
  }
`;

const ProcessingSpinner = ({ isWebview }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Spinner />
      <div>
        <TitleSmall500>{isWebview ? 'We’re upgrading you now' : 'Updating your account'}</TitleSmall500>
        <BodyMedium400>
          {isWebview
            ? 'Please stay on this screen — it might take us a few minutes.'
            : 'Please do not refresh or leave this screen.'}
        </BodyMedium400>
      </div>
    </Container>
  );
};

ProcessingSpinner.propTypes = {
  isWebview: PropTypes.bool.isRequired,
};

export default ProcessingSpinner;
