import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RecurlyProvider } from '@recurly/react-recurly';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '@nebula/theme';

import PaymentCaptureMethod from '../PaymentCaptureMethod';
import PaymentContextProvider, { PAYMENT_STAGES, PaymentContext } from './PaymentContextProvider';
import PaymentMethodChange from '../PaymentMethodChange';
import PaymentMethodAuthorization from '../PaymentMethodAuthorization';
import PaymentProcessing from '../PaymentProcessing';
import { getEnv } from '../Context/env';
import PaymentChangeAuthorization from '../PaymentChangeAuthorization';
import ExistingPaymentDetails from '../PaymentMethodChange/ExistingPaymentDetails';
import PaymentBackNavigation from '../PaymentBackNavigation/PaymentBackNavigation';

export const PaymentStages = () => {
  const paymentContext = useContext(PaymentContext);
  const { paymentStage, isWebview, tokens } = paymentContext;

  let stageComponent = null;

  switch (paymentStage) {
    case PAYMENT_STAGES.REQUIRES_PAYMENT_METHOD:
      stageComponent = <PaymentCaptureMethod />;
      break;
    case PAYMENT_STAGES.REQUIRES_AUTHORIZATION:
      stageComponent = <PaymentMethodAuthorization />;
      break;
    case PAYMENT_STAGES.REQUIRES_FULFILLMENT:
      stageComponent = <PaymentProcessing />;
      break;
    default:
      return null;
  }

  const userHasEnteredPaymentDetails = tokens?.paymentMethod || tokens?.isUsingExistingMethod;

  return (
    <React.Fragment>
      {!isWebview && userHasEnteredPaymentDetails && <PaymentBackNavigation />}
      {stageComponent}
    </React.Fragment>
  );
};

export const PaymentMethodUpdateStages = () => {
  const paymentContext = useContext(PaymentContext);
  const { paymentStage } = paymentContext;

  let stageComponent;

  switch (paymentStage) {
    case PAYMENT_STAGES.REQUIRES_PAYMENT_METHOD:
    case PAYMENT_STAGES.PAYMENT_METHOD_UPDATED:
      stageComponent = <PaymentMethodChange />;
      break;
    case PAYMENT_STAGES.REQUIRES_AUTHORIZATION:
      stageComponent = <PaymentChangeAuthorization />;
      break;
    default:
      return null;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <ExistingPaymentDetails />
      {stageComponent}
    </ThemeProvider>
  );
};

const PaymentOrchestrator = ({ upgradeOffer, isTrial, isMethodChange, isWebview }) => {
  const { REACT_APP_RECURLY_PUBLIC_KEY: key } = getEnv();

  return (
    <PaymentContextProvider
      upgradeOffer={upgradeOffer}
      isTrial={isTrial}
      isWebview={isWebview}
      isMethodChange={isMethodChange}
    >
      <RecurlyProvider publicKey={key}>
        {isMethodChange ? <PaymentMethodUpdateStages /> : <PaymentStages />}
      </RecurlyProvider>
    </PaymentContextProvider>
  );
};

PaymentOrchestrator.propTypes = {
  upgradeOffer: PropTypes.shape({
    family: PropTypes.string,
    name: PropTypes.string,
    upc: PropTypes.string,
    id: PropTypes.string,
    descriptors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  isMethodChange: PropTypes.bool,
  isTrial: PropTypes.bool,
  isWebview: PropTypes.bool,
};

PaymentOrchestrator.defaultProps = {
  isMethodChange: false,
  isTrial: false,
  isWebview: false,
};

export default PaymentOrchestrator;
