import { Modal } from '@nebula/modal';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PaymentContext } from '../PaymentOrchestrator/PaymentContextProvider';

const PaymentBackNavigation = () => {
  const history = useHistory();
  const { restartPaymentFlow } = useContext(PaymentContext);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    const unblock = history.block((_, action) => {
      if (action === 'POP') {
        setShowConfirm(true);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history]);

  const onBackNavigation = () => {
    setShowConfirm(false);
    restartPaymentFlow();
  };

  const onCancelNavigation = () => {
    setShowConfirm(false);
  };

  return (
    showConfirm && (
      <Modal
        header="Leave this page?"
        body="You have not completed your payment."
        isShowing={showConfirm}
        canSkipModal={false}
        zIndex={10}
        actions={[
          { label: 'Yes', onClick: onBackNavigation, buttonKind: 'secondaryBlue' },
          { label: 'No', onClick: onCancelNavigation, buttonKind: 'secondaryBlue' },
        ]}
      />
    )
  );
};

export default PaymentBackNavigation;
