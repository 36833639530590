import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BodyLarge400, BodyLarge500, TitleSmall500 } from '@nebula/global-styles';

import GUIDANCE_MESSAGES from './constants/messaging';
import { PAYMENT_LOG_STEPS } from '../../constants/paymentLogSteps';
import { Guidance } from './index.styles';
import { Main, Header, Review, Methods } from '../PaymentCaptureMethod/index.styles';
import PaymentHeading from '../PaymentCaptureMethod/PaymentHeading';
import { PaymentContext } from '../PaymentOrchestrator/PaymentContextProvider';
import paymentAuthorizedTransition from '../PaymentOrchestrator/transitions/paymentAuthorizedTransition';
import useTrackUpsellStage from '../PaymentOrchestrator/hooks/useTrackUpsellStage';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../PaymentOrchestrator/constants/paymentErrors';
import { trackSwitchAuthFailure, trackSwitchAuthReady, trackSwitchAuthSuccess } from '../../helpers/switch/analytics';
import AuthorizationPrompt from './AuthorizationPrompt';
import useSplits from '../../hooks/useSplits';
import { splitsList } from '../../constants';

const PaymentMethodAuthorization = () => {
  const paymentContext = useContext(PaymentContext);
  const { tokens, raisePaymentError, restartPaymentFlow, isWebview } = paymentContext;

  const splits = useSplits([splitsList.debugRecurlyThreeDs]);
  const debugRecurlyThreeDs = splits[splitsList.debugRecurlyThreeDs];

  const trackUpsellStage = useTrackUpsellStage();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    trackSwitchAuthReady();
  }, []);

  useEffect(() => {
    trackUpsellStage(PAYMENT_LOG_STEPS.AUTHORISING_PAYMENT_METHOD);
  }, [trackUpsellStage]);

  const handleSuccess = outputToken => {
    trackSwitchAuthSuccess();
    trackUpsellStage(PAYMENT_LOG_STEPS.PAYMENT_AUTHORISED);
    paymentAuthorizedTransition(paymentContext, outputToken.id)(dispatch);
  };

  const handleFailure = error => {
    if (debugRecurlyThreeDs) {
      // eslint-disable-next-line no-console
      console.error('auth failure', error);
    }
    trackSwitchAuthFailure(error?.code);
    trackUpsellStage(PAYMENT_LOG_STEPS.AUTHORISING_PAYMENT_METHOD_FAILED);
    raisePaymentError({
      message: error?.message,
      group: PAYMENT_ERROR_GROUPS.AUTHORIZATION,
      type: PAYMENT_ERROR_TYPES.AUTHORIZATION.UNSUCCESSFUL_3DS,
    });
    restartPaymentFlow();
  };

  return (
    <React.Fragment>
      <Header>
        <PaymentHeading />
      </Header>
      <Main>
        <Review>
          <Guidance>
            <TitleSmall500>Confirm your payment</TitleSmall500>
            <BodyLarge400 as="span">{`${GUIDANCE_MESSAGES.HEADING} `}</BodyLarge400>
            <BodyLarge500 as="span">
              {isWebview ? GUIDANCE_MESSAGES.WARNING_APP : GUIDANCE_MESSAGES.WARNING_WEB}
            </BodyLarge500>
          </Guidance>
        </Review>
        <Methods>
          <AuthorizationPrompt
            actionTokenId={tokens.authorizationInput}
            onToken={handleSuccess}
            onError={handleFailure}
          />
        </Methods>
      </Main>
    </React.Fragment>
  );
};

export default PaymentMethodAuthorization;
