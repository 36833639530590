import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { BodyLarge400, BodyMedium400, BodyLarge500, BodyMedium500 } from '@nebula/global-styles';
import { useMediaQuery } from '@nebula/hooks';

import { products } from '../../../constants';
import { FamilyName } from '../constants';
import useBillingChangeInfo from '../hooks/useBillingChangeInfo';

dayjs.extend(advancedFormat);

const SubHeading = ({ isTrial, upgradeOffer }) => {
  const theme = useContext(ThemeContext);
  const { isDeferredUpsell, nextBillingDate, isProRataMovement } = useBillingChangeInfo({ upgradeOffer, isTrial });
  const currentSubscription = useSelector(state => state.subscriptions.current);

  const isMovingToIdentity = upgradeOffer.family === products.identity;
  const subscriptionEnd = nextBillingDate.format('Do MMMM');
  const isDesktop = useMediaQuery(theme.media.smallDesktopMin);
  const SubHeadingText = isDesktop ? BodyLarge400 : BodyMedium400;
  const SubHeadingStrong = isDesktop ? BodyLarge500 : BodyMedium500;

  return (
    <React.Fragment>
      {isTrial && isMovingToIdentity && (
        <SubHeadingText>With complimentary access to CreditExpert features.</SubHeadingText>
      )}
      {isDeferredUpsell && (
        <SubHeadingText>
          You’ll keep {FamilyName[currentSubscription.family]} until{' '}
          <SubHeadingStrong as="strong">{subscriptionEnd}</SubHeadingStrong>
        </SubHeadingText>
      )}
      {isProRataMovement && (
        <SubHeadingText>Switch your subscription and get instant access to all the extra benefits.</SubHeadingText>
      )}
    </React.Fragment>
  );
};

SubHeading.propTypes = {
  upgradeOffer: PropTypes.shape({
    family: PropTypes.string.isRequired,
  }).isRequired,
  isTrial: PropTypes.bool.isRequired,
};

export default SubHeading;
