import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import nebulaTheme from '@nebula/theme';
import GlobalStyles from '@nebula/global-styles';
import { sendMobileErrorNotification, sendMobileLoadedNotification } from '@experian-uk/web-common-mobile-view-helpers';

import isValidSwitch from '../../../helpers/switch/isValidSwitch';
import usePageView from '../../../hooks/usePageView';
import useTargetOffer from '../../../hooks/useTargetOffer';
import useCurrentOffer from '../../../hooks/useCurrentOffer';
import { products } from '../../../constants';
import PaymentOrchestrator from '../../../components/PaymentOrchestrator';
import { trackSwitchRejected } from '../../../helpers/switch/analytics';
import trackStartUpsell from '../../../actions/upsellstarttrackerservice/trackStartUpsell';

const PAGE_NAME = 'ProdMoveSwitchPageMobile';

const WEBVIEW_SUPPORTED_UPGRADE_FAMILIES = [products.identity, products.credit_expert];

const MobileSwitchPage = () => {
  const dispatch = useDispatch();
  const currentOffer = useCurrentOffer();
  const { targetOffer, targetFamily, isSourcedFromUrl, isTrial } = useTargetOffer();
  const validSwitch = isValidSwitch(currentOffer, targetOffer);
  const isFreeUser = currentOffer?.family === products.basic;
  const webviewSupported = isFreeUser && WEBVIEW_SUPPORTED_UPGRADE_FAMILIES.includes(targetFamily);

  usePageView(PAGE_NAME, {
    productChange: { switchingTo: targetFamily, isTrial, fromDeeplink: isSourcedFromUrl },
  });

  useEffect(() => {
    dispatch(trackStartUpsell(targetFamily, isTrial));
  }, [dispatch, targetFamily, isTrial]);

  useEffect(() => {
    if (targetOffer && validSwitch && webviewSupported) {
      sendMobileLoadedNotification();
    } else {
      trackSwitchRejected();
      sendMobileErrorNotification();
    }
  }, [targetOffer, validSwitch, webviewSupported]);

  if (!targetOffer || !validSwitch || !webviewSupported) {
    return null;
  }

  return (
    <ThemeProvider theme={nebulaTheme}>
      <GlobalStyles />
      <PaymentOrchestrator upgradeOffer={targetOffer} isTrial={isTrial} isWebview />
    </ThemeProvider>
  );
};

export default MobileSwitchPage;
