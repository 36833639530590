import { useContext } from 'react';

import { PaymentMethodTypes } from '../../PaymentCaptureMethod/constants';
import { splitsList } from '../../../constants';
import useExistingPaymentMethod from '../../PaymentCaptureMethod/hooks/useExistingPaymentMethod';
import useSplits from '../../../hooks/useSplits';
import { PaymentContext } from '../PaymentContextProvider';
import { trackApplePayWebRollout } from '../../../helpers/switch/analytics';

const usePaymentMethods = () => {
  const paymentMethods = [PaymentMethodTypes.Card];
  const changeMethods = [PaymentMethodTypes.Card];

  const { isWebview, isMethodChange } = useContext(PaymentContext);

  const { existingPaymentType } = useExistingPaymentMethod();

  const splits = useSplits([splitsList.applePayWebRollout]);
  const applePayUpsellTest = splits[splitsList.applePayWebRollout];

  const alreadyUsingApplePay = existingPaymentType === PaymentMethodTypes.ApplePay;
  const splitTreatment = applePayUpsellTest ? 'on' : 'off';

  if (typeof window !== 'undefined' && !!window?.ApplePaySession?.canMakePayments?.()) {
    changeMethods.push(PaymentMethodTypes.ApplePay);

    // Temporary - this tracks the rollout of apple pay for web users, which will be scaled shortly after the initial provider change
    if (!isMethodChange && !isWebview) {
      trackApplePayWebRollout(alreadyUsingApplePay ? 'existing' : splitTreatment);
    }

    if (isWebview || alreadyUsingApplePay || applePayUpsellTest) {
      paymentMethods.push(PaymentMethodTypes.ApplePay);
    }
  }

  return isMethodChange ? changeMethods : paymentMethods;
};

export default usePaymentMethods;
