import fetch from '../fetch';

import { trackBillingProviderIdentifier } from '../../helpers/switch/analytics';

export const FETCH_BILLING_PROVIDER = 'FETCH_BILLING_PROVIDER';
export const BILLING_PROVIDER_FETCHED = 'BILLING_PROVIDER_FETCHED';

export const BILLING_PROVIDER_ERROR = 'unable to establish billing provider';

export default () => async dispatch => {
  dispatch({ type: FETCH_BILLING_PROVIDER });

  let billingProviderName;

  try {
    const billing = await dispatch(fetch('/billingsubscriptions/providername'));
    billingProviderName = billing?.data?.[0]?.BillingProviderName;

    trackBillingProviderIdentifier(billingProviderName ?? "unknown");

    if (!billingProviderName) {
      throw new Error(BILLING_PROVIDER_ERROR);
    }
  } catch (error) {
    /*
      re-throwing here for clarity - a consumer should handle this error and prevent the application going further if necessary, as knowing the billing provider
      is essential.
    */
    throw new Error(BILLING_PROVIDER_ERROR);
  }

  return dispatch({ type: BILLING_PROVIDER_FETCHED, payload: billingProviderName });
};
