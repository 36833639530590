import React, { useContext, useRef, useState } from 'react';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@nebula/hooks';
import { ThemeContext } from 'styled-components';

import { TitleMedium500, TitleSmall500 } from '@nebula/global-styles';
import PaymentCardForm from '../PaymentCaptureMethod/PaymentCardForm';
import { PaymentDivider, PaymentMethod, Spinner } from '../PaymentCaptureMethod/PaymentMethodEntry/index.styles';
import { getEnv } from '../Context/env';
import paymentMethodChangeTransition from '../PaymentOrchestrator/transitions/paymentMethodChangeTransition';
import { PaymentContext } from '../PaymentOrchestrator/PaymentContextProvider';
import {
  Alert,
  ApplePayOption,
  LargePaymentChangeTitle,
  MethodUpdateButton,
  PaymentChangeButtons,
  PaymentInformationContainer,
  StandardPaymentChangeTitle,
} from './index.styles';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../PaymentOrchestrator/constants/paymentErrors';
import PaymentError from '../PaymentCaptureMethod/PaymentError';
import { trackPaymentMethodChangeCancel, trackPaymentMethodChangeError } from '../../helpers/switch/analytics';
import ApplePay from '../PaymentCaptureMethod/PaymentMethodEntry/ApplePay';
import useExistingPaymentMethod from '../PaymentCaptureMethod/hooks/useExistingPaymentMethod';
import { PaymentMethodTypes } from '../PaymentCaptureMethod/constants';
import usePaymentMethods from '../PaymentOrchestrator/hooks/usePaymentMethods';

const onMethodChangeAvailable = node => {
  if (node) {
    node.scrollIntoView({ behavior: 'smooth' });
  }
};

const PaymentMethodChange = () => {
  const { REACT_APP_RECURLY_PUBLIC_KEY: key } = getEnv();

  const paymentContext = useContext(PaymentContext);
  const { clearPaymentErrors, raisePaymentError, paymentErrors, paymentChangeActive, setPaymentChangeActive } =
    paymentContext;

  const dispatch = useDispatch();
  const formRef = useRef(null);
  const errorRef = useRef(null);

  const [processingMethodUpdate, setProcessingMethodUpdate] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const theme = useContext(ThemeContext);
  const useLargerCopy = useMediaQuery(theme.media.tabletPortraitMin);
  const { existingPaymentType } = useExistingPaymentMethod();

  const ChangePaymentTitle = useLargerCopy ? LargePaymentChangeTitle : StandardPaymentChangeTitle;
  const CardOptionTitle = useLargerCopy ? TitleMedium500 : TitleSmall500;
  const availablePaymentMethods = usePaymentMethods();
  const showApplePay = availablePaymentMethods.includes(PaymentMethodTypes.ApplePay);

  const handleFormValidityChange = isValid => setIsFormValid(isValid);

  const customer = useSelector(state => state.auth?.currentUser);
  const address = customer?.address?.[0];

  const handleCancel = () => {
    trackPaymentMethodChangeCancel();
    clearPaymentErrors();
    setProcessingMethodUpdate(false);
    setPaymentChangeActive(false);
  };

  const handleSubmit = () => {
    clearPaymentErrors();
    setProcessingMethodUpdate(true);
  };

  const handleSuccess = async (paymentMethodToken, newPaymentType) => {
    const runBeforeTransition = () => setProcessingMethodUpdate(false);

    const updatedPaymentMethodType =
      Object.values(PaymentMethodTypes).find(paymentType => newPaymentType.includes(paymentType)) ?? 'unknown';

    const methodChangeAnalytics = `${existingPaymentType}->${updatedPaymentMethodType}`.toLowerCase();

    await paymentMethodChangeTransition(
      paymentContext,
      paymentMethodToken.id,
      methodChangeAnalytics,
      runBeforeTransition
    )(dispatch);
  };

  const handleFailure = error => {
    trackPaymentMethodChangeError(error.message);
    raisePaymentError({
      message: error.message,
      group: PAYMENT_ERROR_GROUPS.METHOD_CHANGE,
      type: PAYMENT_ERROR_TYPES.METHOD_CHANGE.UNSUCCESSFUL,
    });
    setProcessingMethodUpdate(false);
    errorRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (!paymentChangeActive) return null;

  const paymentError = paymentErrors.length ? paymentErrors[paymentErrors.length - 1] : null;

  return (
    <PaymentInformationContainer>
      {paymentChangeActive && (
        <div ref={onMethodChangeAvailable}>
          <ChangePaymentTitle>Change payment method</ChangePaymentTitle>
          {paymentError && (
            <Alert ref={errorRef} key={paymentError.type}>
              <PaymentError error={paymentError} />
            </Alert>
          )}
          <RecurlyProvider publicKey={key}>
            <Elements>
              {showApplePay && (
                <ApplePayOption disabled={processingMethodUpdate}>
                  <ApplePay isMethodChange onSuccess={handleSuccess} onError={handleFailure} />
                  <PaymentDivider>or</PaymentDivider>
                  <CardOptionTitle>Pay by card</CardOptionTitle>
                </ApplePayOption>
              )}
              <PaymentMethod>
                <PaymentCardForm
                  innerRef={formRef}
                  customer={customer}
                  address={address}
                  onSubmitAttempt={handleSubmit}
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  onFormValidityChange={handleFormValidityChange}
                />
              </PaymentMethod>
              <PaymentChangeButtons>
                <MethodUpdateButton buttonKind="secondaryBlue" label="Cancel" type="button" onClick={handleCancel} />
                <MethodUpdateButton
                  data-automation-test-element="confirmation-method-change-button"
                  disabled={!isFormValid || processingMethodUpdate}
                  label={processingMethodUpdate ? <Spinner /> : 'Save card'}
                  buttonKind="primaryBlue"
                  type="submit"
                  form="recurly-payment-form"
                />
              </PaymentChangeButtons>
            </Elements>
          </RecurlyProvider>
        </div>
      )}
    </PaymentInformationContainer>
  );
};

export default PaymentMethodChange;
