import { useEffect } from 'react';
import { useRecurly } from '@recurly/react-recurly';

import { FamilyName, PaymentMethodTypes, TrialDuration } from '../constants';

/**
 * Custom hook to handle Apple Pay integration with Recurly.
 *
 * @param {HTMLElement} applePayButton - The Apple Pay button element.
 * @param {Object} billingDetails - The billing details for the payment.
 * @param {boolean} billingDetails.isMethodChange - Indicates if the payment method is being changed.
 * @param {Object} billingDetails.upgradeOffer - The upgrade offer details.
 * @param {boolean} billingDetails.isTrial - Indicates if the payment is for a trial period.
 * @param {string} billingDetails.price - The price of the subscription.
 * @param {string} billingDetails.proRataPrice - The pro-rata price for the subscription.
 * @param {boolean} billingDetails.isProRataMovement - Indicates if the payment is a pro-rata movement.
 * @param {moment.Moment} billingDetails.billingStartDate - The start date of the billing period.
 * @param {moment.Moment} billingDetails.nextBillingDate - The next billing date.
 * @param {moment.Moment} billingDetails.remainingTimeUntilDate - The remaining time until the next billing date.
 * @param {Function} onSuccess - Callback function to be called on successful payment authorization.
 * @param {Function} onError - Callback function to be called on payment authorization error.
 *
 * @returns {Object} The Apple Pay configuration object.
 */

const useApplePay = (applePayButton, billingDetails, onSuccess, onError) => {
  const {
    isMethodChange,
    upgradeOffer,
    isTrial,
    price,
    proRataPrice,
    isProRataMovement,
    billingStartDate,
    nextBillingDate,
    remainingTimeUntilDate,
  } = billingDetails;

  const recurly = useRecurly();

  const clickAbortController = new AbortController();

  const nextBill = nextBillingDate?.format('DD MMMM YYYY');
  const subscriptionStart = billingStartDate.format('DD MMMM YYYY');
  const delayedStartDate = remainingTimeUntilDate?.format('DD MMMM YYYY');

  const analyticsIdentifier = isMethodChange ? PaymentMethodTypes.ApplePay : 'new_apple_pay';
  const isApplePayButtonReady = applePayButton;

  const regularBilling = {
    label: FamilyName[upgradeOffer.family],
    amount: price,
    paymentTiming: 'recurring',
    recurringPaymentIntervalUnit: 'month',
    recurringPaymentIntervalCount: 1,
    recurringPaymentStartDate: subscriptionStart,
  };

  const trialBilling = {
    label: `Free ${TrialDuration} Day Trial`,
    amount: '0.00',
    paymentTiming: 'recurring',
    recurringPaymentIntervalUnit: 'day',
    recurringPaymentIntervalCount: TrialDuration,
    recurringPaymentEndDate: subscriptionStart,
    ...(delayedStartDate && { recurringPaymentStartDate: delayedStartDate }),
  };

  const proRata = {
    label: 'Today’s payment',
    amount: proRataPrice,
    paymentTiming: 'immediate',
    type: 'final',
  };

  const methodChangeBilling = {
    label: FamilyName[upgradeOffer.family],
    amount: price,
    paymentTiming: 'recurring',
    recurringPaymentStartDate: nextBill,
  };

  const paymentDescription = FamilyName[upgradeOffer.family];

  const paymentRequest = isMethodChange
    ? {
        total: {
          label: 'Experian',
          amount: price,
        },
        recurringPaymentRequest: {
          paymentDescription,
          regularBilling: methodChangeBilling,
        },
      }
    : {
        recurringPaymentRequest: {
          paymentDescription,
          regularBilling,
          ...(isTrial && { trialBilling }),
        },
        ...(isProRataMovement && {
          lineItems: [proRata],
        }),
      };

  const applePayConfig = {
    country: 'GB',
    currency: 'GBP',
    label: 'Experian',
    total: isTrial ? 0 : price,
    recurring: true,
    paymentRequest,
    callbacks: {
      onPaymentAuthorized: ({ payment }) => {
        const { recurlyToken } = payment;
        onSuccess(recurlyToken, analyticsIdentifier);
      },
    },
  };

  useEffect(() => {
    if (!isApplePayButtonReady) return undefined;

    const applePay = recurly.ApplePay(applePayConfig);

    const handleClick = () => {
      applePay.begin();
    };

    applePay.ready(() => {
      applePayButton.addEventListener('click', handleClick, { signal: clickAbortController.signal });
    });

    applePay.on('error', err => {
      onError(err, analyticsIdentifier);
    });

    return () => {
      clickAbortController.abort('applepay_recreate');
    };
  }, [isApplePayButtonReady, applePayButton, applePayConfig]);

  return applePayConfig;
};

export default useApplePay;
