import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { go, push } from 'connected-react-router';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { useAccordion, Accordion, FlexibleAccordionItem } from '@nebula/accordion';
import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';
import GlobalStyles from '@nebula/global-styles';
import nebulaTheme from '@nebula/theme';
import { ErrorAlert } from '@nebula/alert';

import { getEnv } from '../../Context/env';
import { normalizeField } from '../../../helpers/forms';
import checkClientValidVoucher from '../../../helpers/checkClientValidVoucher';
import InView from '../../InView';
import validateVoucher from '../../../actions/voucherValidation';

import { BillingSubsModal } from './billingSubsModal';
import {
  AccordionWrapper,
  AccordionTitle,
  VoucherCopy,
  VoucherButton,
  VoucherButtonWrapper,
  VoucherTextInput,
} from './breachVoucherExtension.styles';

dayjs.extend(advancedFormat);

const triggerEcd = ({ componentSuffix, actionName, error = false, data = {} }) =>
  Analytics.publishOnce(
    InteractionInputEvent.fromObject({
      component: `breach_current_subscription_card_${componentSuffix}`,
      action: actionName,
      status: !error ? 'success' : 'failure',
      data,
    })
  );

const BreachVoucherExtension = () => {
  const [billingSubsExtendModalOpen, setBillingSubsExtendModalOpen] = useState(false);
  const [failureMessage, setFailureMessage] = useState('');
  const [fieldState, setFieldState] = useState('standard');
  const [inputDisabled, setInputDisabled] = useState(false);
  const [inputHelperMessage, setInputHelperMessage] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const { currentOpenItem, updateOpenItem } = useAccordion();

  const dispatch = useDispatch();
  const voucherResult = useSelector(state => state?.voucherValidationResult);

  useEffect(() => {
    if (voucherResult?.error) {
      triggerEcd({
        componentSuffix: 'extend',
        actionName: 'input',
        error: true,
        data: {
          errorType: 'service',
          fieldName: 'voucher_code',
          failureReason: voucherResult.ecdFailureReason,
        },
      });
      setFailureMessage(voucherResult?.error);
      setBillingSubsExtendModalOpen(false);
    }
    if (voucherResult && voucherResult?.voucherCode !== '' && !voucherResult?.fetching) {
      triggerEcd({ componentSuffix: 'extend', actionName: 'input' });
      // Refresh the page to reset the subscription end date and associated page content
      dispatch(push('?breachExtensionVoucher=true'));
      dispatch(go());
    }
  }, [voucherResult]);

  const clearValidation = () => {
    setInputHelperMessage('');
    setFieldState('standard');
  };

  const handleAccordionToggle = () => {
    triggerEcd({ componentSuffix: 'add_code_accordion', actionName: 'click' });
    updateOpenItem('breachVoucherCode');
  };

  const handleInputEntry = e => {
    clearValidation();
    setVoucherCode(normalizeField.uppercase(e.target.value));
  };

  const handleInputPaste = e => {
    e.preventDefault();
    clearValidation();
    setVoucherCode(normalizeField.uppercase(e.clipboardData.getData('text/plain').trim()));
  };

  const handleVoucherSubmission = async e => {
    e.preventDefault();
    setFailureMessage('');
    triggerEcd({ componentSuffix: 'add_code_cta', actionName: 'click' });
    setInputDisabled(true);
    const voucher = normalizeField.uppercase(voucherCode.trim());
    const checkVoucher = checkClientValidVoucher(voucher, getEnv().REACT_APP_ENV);

    if (checkVoucher.shouldShowClientSideError === true) {
      triggerEcd({
        componentSuffix: 'extend',
        actionName: 'input',
        error: true,
        data: { errorType: 'field_error', fieldName: 'voucher_code', failureReason: checkVoucher.ecdFailureReason },
      });
      setInputHelperMessage(checkVoucher.voucherErrorMessage);
      setInputDisabled(false);
      setFieldState('error');
    }

    if (checkVoucher.shouldShowClientSideError === false) {
      setBillingSubsExtendModalOpen(true);
      dispatch(validateVoucher(voucher, false, true));
    }
  };

  const defaultState = voucherResult?.errorCode === null;
  const invalidVoucherError = voucherResult?.ecdFailureReason === 'code_invalid';

  const showTextInput = defaultState || invalidVoucherError || voucherResult?.error;

  return (
    <ThemeProvider theme={nebulaTheme}>
      <GlobalStyles />
      <BillingSubsModal isShowing={billingSubsExtendModalOpen} />
      <InView label="breach_current_subscription_card_add_code_accordion">
        <AccordionWrapper>
          <Accordion heading="h3" {...setAutomationElement(`voucher-extension-accordion`)}>
            <FlexibleAccordionItem
              header={
                <AccordionTitle {...setAutomationElement(`voucher-extension-header`)}>
                  Add new voucher code
                </AccordionTitle>
              }
              id="breachVoucherCode"
              clickHandler={() => handleAccordionToggle()}
              isOpen={currentOpenItem.includes('breachVoucherCode')}
              chevronColor={nebulaTheme.color.brand.darkBlue}
            >
              <React.Fragment>
                {failureMessage !== '' && <ErrorAlert showIcon message={failureMessage} />}
                {showTextInput && (
                  <form>
                    {failureMessage === '' && (
                      <VoucherCopy id="voucherExtensionFormHelper">
                        Extend your complimentary subscription if you have another code.
                      </VoucherCopy>
                    )}
                    <VoucherTextInput
                      aria-labelledby="voucherExtensionFormHelper"
                      disabled={inputDisabled && !showTextInput}
                      helper={inputHelperMessage}
                      id="voucherExtensionFormInput"
                      inputType="standard"
                      onChange={e => handleInputEntry(e)}
                      onPaste={e => handleInputPaste(e)}
                      state={fieldState}
                      uniqueId="voucher-extension-input"
                      value={voucherCode}
                      {...setAutomationElement(`voucher-extension-form-input`)}
                    />
                    <VoucherButtonWrapper>
                      <VoucherButton
                        disabled={inputDisabled && !showTextInput}
                        label="Add new code"
                        {...setAutomationElement(`voucher-extension-form-submit`)}
                        type="submit"
                        onClick={e => handleVoucherSubmission(e)}
                      />
                    </VoucherButtonWrapper>
                  </form>
                )}
              </React.Fragment>
            </FlexibleAccordionItem>
          </Accordion>
        </AccordionWrapper>
      </InView>
    </ThemeProvider>
  );
};

export default BreachVoucherExtension;
