import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TitleSmall400, TitleSmall500, TitleXSmall400, TitleXSmall500 } from '@nebula/global-styles';
import { useMediaQuery } from '@nebula/hooks';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { ThemeContext } from 'styled-components';
import { SuccessAlert } from '@nebula/alert';
import { ApplePay } from '@epl/icons';
import {
  ChangePaymentMethodButton,
  Entry,
  InformationCard,
  LargePaymentInfoTitle,
  PaymentInfo,
  PaymentMethodDetails,
  StandardPaymentInfoTitle,
} from './index.styles';
import { familyName } from '../../../constants';
import useExistingPaymentMethod from '../../PaymentCaptureMethod/hooks/useExistingPaymentMethod';
import { SmallCardIcon } from '../../PaymentCaptureMethod/PaymentCardForm/Icons';
import { PAYMENT_STAGES, PaymentContext } from '../../PaymentOrchestrator/PaymentContextProvider';
import { Alert } from '../index.styles';
import { trackPaymentMethodChangeStarted } from '../../../helpers/switch/analytics';
import { PaymentMethodTypes } from '../../PaymentCaptureMethod/constants';
import usePaymentMethods from '../../PaymentOrchestrator/hooks/usePaymentMethods';

dayjs.extend(advancedFormat);

const InformationRow = ({ label, value, testId, mask }) => {
  const theme = useContext(ThemeContext);
  const useLargerCopy = useMediaQuery(theme.media.tabletPortraitMin);

  const EntryTitle = useLargerCopy ? TitleSmall500 : TitleXSmall500;
  const EntryValue = useLargerCopy ? TitleSmall400 : TitleXSmall400;

  return (
    <Entry data-hook={mask ? 'fs-exclude-element' : undefined}>
      <EntryTitle>{label}:</EntryTitle> <EntryValue data-automation-test-element={testId}>{value}</EntryValue>
    </Entry>
  );
};

const onSuccessAlertRef = node => {
  if (node) {
    node.scrollIntoView({ behavior: 'smooth' });
  }
};

const ExistingPaymentDetails = () => {
  const currentSubscription = useSelector(state => state.subscriptions?.current?.family);

  const { existingPaymentType, existingPaymentDetails } = useExistingPaymentMethod();

  const { paymentStage, paymentChangeActive, setPaymentChangeActive } = useContext(PaymentContext);
  const theme = useContext(ThemeContext);
  const useLargerCopy = useMediaQuery(theme.media.tabletPortraitMin);
  const PaymentInformationTitle = useLargerCopy ? LargePaymentInfoTitle : StandardPaymentInfoTitle;
  const availablePaymentMethods = usePaymentMethods();

  const onTriggerChangeMethod = () => {
    trackPaymentMethodChangeStarted(availablePaymentMethods, existingPaymentType);
    setPaymentChangeActive(!paymentChangeActive);
  };

  const nextBillingDate = useSelector(state => state.billing?.nextBillingDate);
  const billingPrice = useSelector(state => state.billing?.signupPrice);
  const monthlyBilling = useSelector(state => state.billing?.billingFrequency === 'Monthly');
  const billingDay = dayjs(nextBillingDate).format('Do');
  const nextBillDateLong = dayjs(nextBillingDate).format('Do MMMM YYYY');

  return (
    <PaymentInfo>
      <PaymentInformationTitle>Your payment information</PaymentInformationTitle>
      <InformationCard>
        {currentSubscription && (
          <InformationRow label="Subscription" value={familyName[currentSubscription]} testId="YourSubscription" />
        )}
        {!!billingPrice && <InformationRow label="Price" value={`£${billingPrice}`} testId="YourPrice" />}
        {nextBillingDate && monthlyBilling && billingDay && (
          <InformationRow label="Billing date" value={`${billingDay} of each month`} testId="YourBillingDate" />
        )}
        {nextBillingDate && nextBillDateLong && (
          <InformationRow label="Next bill" value={nextBillDateLong} testId="YourNextBill" />
        )}
      </InformationCard>
      {existingPaymentType && (
        <PaymentMethodDetails>
          {paymentStage === PAYMENT_STAGES.PAYMENT_METHOD_UPDATED && (
            <Alert ref={onSuccessAlertRef}>
              <SuccessAlert title="Payment method updated" message="" showIcon />
            </Alert>
          )}
          {existingPaymentType === PaymentMethodTypes.Card && (
            <React.Fragment>
              <InformationRow
                label="Card type"
                value={<SmallCardIcon creditCardType={existingPaymentDetails.creditCardType} />}
                testId="CardPaymentMethod"
              />
              <InformationRow label="Card ending" value={existingPaymentDetails.cardNumber} mask />
              <InformationRow label="Expires" value={existingPaymentDetails.cardExpiryDateLong} mask />
            </React.Fragment>
          )}
          {existingPaymentType === PaymentMethodTypes.ApplePay && (
            <InformationRow label="Payment method" value={<ApplePay />} testId="ApplePayPaymentMethod" />
          )}
          <ChangePaymentMethodButton
            type="button"
            label="Change payment method"
            buttonKind="secondaryBlue"
            disabled={paymentChangeActive}
            onClick={onTriggerChangeMethod}
            data-automation-test-element="change-payment-method"
          />
        </PaymentMethodDetails>
      )}
    </PaymentInfo>
  );
};

InformationRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  testId: PropTypes.string,
  mask: PropTypes.bool,
};

InformationRow.defaultProps = {
  testId: '',
};

export default ExistingPaymentDetails;
